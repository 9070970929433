<template>
    <div class="team-member-wrapper section-space--pt_120 section-space--pb_60">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section-title text-center section-space--mb_60">
                        <h3 class="section-title mb-15 text-center">The Ones Who Started It All</h3>
                        <!-- <h3 class="font-weight--light mb-15">The Ones Who Started It All</h3> -->
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 col-md-6"></div>
                <div class="col-lg-3 col-md-6">
                    <div class="grid-item">
                        <div class="ht-team-member text-center">
                            <div class="team-image">
                                <img class="img-fluid" style="border-radius: 50%;" src="../assets/img/team/russell.jpg" alt="image">
                            </div>
                            <div class="team-info text-center">
                                <h5 class="name">Russell Menezes</h5>
                                <div class="position">Chief Executive Officer</div>
                                <!-- <div class="description">Suspendisse congue hendrerit iaculis. Integer vitae consequat nibh. Donec elementum, eros non iaculis tempor, nibh dui venenatis massa</div> -->
                                <div class="social-networks">
                                    <div class="inner">
                                        <a 
                                            href="https://www.linkedin.com/in/russellmenezes89/"
                                            class=" hint--bounce hint--top  hint--primary"
                                            aria-label="LinkedIn"
                                            target="_blank"
                                        ><i class="fab fa-linkedin"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="grid-item">
                        <div class="ht-team-member text-center">
                            <div class="team-image">
                                <img class="img-fluid" style="border-radius: 50%;" src="../assets/img/team/teryne.jpg" alt="image">
                            </div>
                            <div class="team-info text-center">
                                <h5 class="name">Teryne Yong</h5>
                                <div class="position">Chief Technology Officer</div>
                                <!-- <div class="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam id ligula posuere, semper nunc eu, molestie quam. Ut mi leo, efficitur fringilla nibh vel, tempor congue urna.</div> -->
                                <div class="social-networks">
                                    <div class="inner">
                                        <a 
                                            href="https://www.linkedin.com/in/teryne/"
                                            class=" hint--bounce hint--top  hint--primary"
                                            aria-label="LinkedIn"
                                            target="_blank"
                                        ><i class="fab fa-linkedin"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-lg-3 col-md-6">
                    <div class="grid-item">
                        <div class="ht-team-member text-center">
                            <div class="team-image">
                                <img class="img-fluid" src="../assets/img/team/avatar-05.png" alt="">
                            </div>
                            <div class="team-info text-center">
                                <h5 class="name">Fannie Garcia </h5>
                                <div class="position">Director</div>
                                <div class="social-networks">
                                    <div class="inner">
                                       <a href="#" class=" hint--bounce hint--top  hint--primary" aria-label="Facebook"><i class="fab fa-facebook"></i></a>
                                        <a href="#" class=" hint--bounce hint--top hint--primary" aria-label="Twitter"><i class="fab fa-twitter"></i></a>
                                        <a href="#" class=" hint--bounce hint--top hint--primary" aria-label="Instagram"><i class="fab fa-instagram"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="grid-item">
                        <div class="ht-team-member text-center">
                            <div class="team-image">
                                <img class="img-fluid" src="../assets/img/team/avatar-04.png" alt="">
                            </div>
                            <div class="team-info text-center">
                                <h5 class="name"> Dollie Horton </h5>
                                <div class="position">Marketing</div>
                                <div class="social-networks">
                                    <div class="inner">
                                        <a href="#" class=" hint--bounce hint--top  hint--primary" aria-label="Facebook"><i class="fab fa-facebook"></i></a>
                                        <a href="#" class=" hint--bounce hint--top hint--primary" aria-label="Twitter"><i class="fab fa-twitter"></i></a>
                                        <a href="#" class=" hint--bounce hint--top hint--primary" aria-label="Instagram"><i class="fab fa-instagram"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TeamOne',
    }
</script>

