<template>
    <div >
        <div class="row">
            <div v-for="item in items" :key="item.id" class="col-xl-4 col-lg-4 col-md-6 wow move-up">
                <div class="service-grid">
                    <div class="ht-service-box--two text-center style-03">
                        <router-link to="">
                            <div class="image mb-20">
                                <img :src="item.icon" class="img-fluid" alt="aeroland-startup-box-icon-01">
                            </div>
                            <div class="content">
                                <h6 class="heading">{{ item.heading }}</h6>
                                <div class="service_text text-justify">{{ item.text }}</div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
    export default {
        name: 'StartUpFeatureTwo',
        data (){
            return {
                items: [
                    {
                       icon: require('../assets/img/icons/cloud-computing.png'), 
                       heading: 'Software as a Service', 
                       text: "Our SaaS solution lets you use your own proprietary data within our AI platform. Having control and flexibility over your data allows you to unlock unique insights and enable more effective decision-making."
                    },
                    {
                        icon: require('../assets/img/icons/global.png'), 
                        heading: 'Information as a Service', 
                        text: "Our subscription-based service leverages the power of AI to extract valuable insights from public databases, allowing your organization to easily access and analyze large amounts of data."
                    },
                    {
                        icon: require('../assets/img/icons/consulting.png'), 
                        heading: 'Consulting Services', 
                        text: 'We offer consulting services for customers who require personalized solutions for their unstructured data challenges. We provide the expert support and guidance you need to effectively leverage your data and make better-informed decisions.'
                    },
                    // {
                    //     icon: require('../assets/img/icons/aeroland-startup-box-icon-04.png'), 
                    //     heading: 'Keyword Search', 
                    //     text: 'Consider this app a platform for connecting people from everywhere based on their preferences.'
                    // },
                    // {
                    //     icon: require('../assets/img/icons/aeroland-startup-box-icon-05.png'), 
                    //     heading: 'Email Optimization', 
                    //     text: 'Consider this app a platform for connecting people from everywhere based on their preferences.'
                    // },
                    // {
                    //     icon: require('../assets/img/icons/aeroland-startup-box-icon-06.png'), 
                    //     heading: 'Web Design', 
                    //     text: 'Consider this app a platform for connecting people from everywhere based on their preferences.'
                    // },
                ]
            }
        }
    }
</script>
