<template>
  <div>
    <Navbar class="is-sticky"/>
    <div class="container radix-nav pt-3 pb-3">
      <PricingSection/>
    </div>
    <FooterMain class="radix-nav" FooterStyle="bg_drak-footer"/>
  </div>
</template>

<script>
import PricingSection from '../components/PricingSection.vue';
import Navbar from '../components/Navbar';
import FooterMain from '../components/FooterMain';

export default {
  name: 'SeparatePricing',
  components: {
    PricingSection,
    Navbar,
    FooterMain
  }
}
</script>