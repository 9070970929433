<template>
    <b-navbar type="light" toggleable="xl" fixed="top" v-b-scrollspy:nav-scroller class="header-area" :class="{'is-sticky': scrolled}">
        <div class="container-fluid container-fluid--cp-150">
            <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
            <b-navbar-brand class="navbar-brand" to="/"><img :src='"../assets/img/logo/coloured-logo-dark-text.png"' alt="logo"></b-navbar-brand>
            <b-btn-group class="header-config-wrapper">
                <!-- <b-btn class="header-config" @click="toggleClass('addClass', 'active')"><i class="far fa-search"></i></b-btn> -->
                <!-- <b-link class="ht-btn ht-btn--outline hire-btn d-none d-xl-block">Login</b-link> -->
            </b-btn-group>
            <b-collapse class="default-nav justify-content-center"  is-nav id="nav_collapse">
                <b-navbar-nav class="navbar-nav main-menu">
                    <!-- <b-nav-item to="/"><span>LANDING</span></b-nav-item> -->
                    <b-nav-item to="/" class="scroll"><span>HOME</span></b-nav-item>
                    <b-nav-item-dropdown class="scroll" text="ABOUT US">
                        <b-dropdown-item to="/#about" class="scroll">What is RadiXplore</b-dropdown-item>
                        <b-dropdown-item to="/#founders" class="scroll">The Founders</b-dropdown-item>
                        <b-dropdown-item to="/values">Mission and Values</b-dropdown-item>
                    </b-nav-item-dropdown>
                    <b-nav-item to="/#products" class="scroll"><span>PRODUCTS</span></b-nav-item>
                    <b-nav-item to="/our-pricing" class="scroll"><span>PRICING</span></b-nav-item>
                    <b-nav-item to="/#features" class="scroll"><span>WHY RADIXPLORE</span></b-nav-item>
                    <!-- <b-nav-item :to="{name: 'Blog'}" exact active-class="active-link"><span>BLOG</span></b-nav-item> -->
                    <!-- <b-nav-item to="/usecases" class="scroll"><span>USE CASES</span></b-nav-item> -->
                    
                    <!-- <b-nav-item href="#testimonials" class="scroll"><span>TESTIMONIALS</span></b-nav-item> -->
                </b-navbar-nav>
            </b-collapse>
            <b-dropdown class="log-in-btn" right  text="Login">
                <b-dropdown-item href="https://app.radixplore.com" target="_blank">
                    RadiXplore Mining
                </b-dropdown-item>
                <b-dropdown-item href="https://petroleum.radixplore.com" target="_blank">
                    RadiXplore Petroleum
                </b-dropdown-item>
            </b-dropdown>
            <b-button class="sign-up-btn" @click="handleSignUp">Sign Up</b-button>
        </div>
    </b-navbar>
</template>

<script>
    export default {
        name:'Navbar',
        data (){
            return {
                load: false,
                limitPosition: 200,
                scrolled: true, // hard code to true for now, no time to manage dark and light background changes
                lastPosition: 500,
            }
        },
        mounted (){
            (function() {
                scrollTo();
            })();

            function scrollTo() {
                const links = document.querySelectorAll('.scroll > a');
                links.forEach(each => (each.onclick = scrollAnchors));
            }

            function scrollAnchors(e, respond = null) {
                const distanceToTop = el => Math.floor(el.getBoundingClientRect().top);
                e.preventDefault();
                var targetID = (respond) ? respond.getAttribute('href') : this.getAttribute('href');
                const targetAnchor = document.querySelector(targetID);
                if (!targetAnchor) return;
                const originalTop = distanceToTop(targetAnchor);
                window.scrollBy({ top: originalTop, left: 0, behavior: 'smooth' });
                const checkIfDone = setInterval(function() {
                    const atBottom = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 190;
                    if (distanceToTop(targetAnchor) === 0 || atBottom) {
                        targetAnchor.tabIndex = '-1';
                        targetAnchor.focus();
                        clearInterval(checkIfDone);
                    }
                }, 800);
            }
        },
        methods: {
            // sticky menu script
            handleScroll() {
                if (this.lastPosition < window.scrollY && this.limitPosition < window.scrollY) {
                    this.scrolled = true;
                    // move up!
                } 
                if (this.lastPosition > window.scrollY) {
                    this.scrolled = true;
                    // move down
                }
                this.lastPosition = window.scrollY;
                this.scrolled = window.scrollY > 50;
            },

            // offcanvas searchbox
            toggleClass(addRemoveClass, className) {
                const el = document.querySelector('#search-overlay');
                if (addRemoveClass === 'addClass') {
                    el.classList.add(className);
                } else {
                    el.classList.remove(className);
                }
            },

            async handleSignUp(){
                this.$router.push('/signup');
            }
        }, 
        created() {
            window.addEventListener("scroll", this.handleScroll);
        },
        destroyed() {
            window.removeEventListener("scroll", this.handleScroll);
        },
    }
</script>
<style lang="scss" >
@import '../assets/scss/variabls.scss';
.log-in-btn {
    .btn {
        background-color: $theme-color--default !important;
        height: 35px;
        text-align: center;  
        width:90px;
        display:flex;
        flex-direction:row;
        justify-content: center;
        align-items:center;
        font-size: 14px   
    }
}

.sign-up-btn {
    border: solid 3px $theme-color--default !important;
    color: $theme-color--default;
    background-color: transparent;
    height: 45px;
    margin-left: 0.5rem;
    padding-bottom: 1rem;
    font-size: 16px;
    line-height: 41px;
}

.sign-up-btn:hover {
    color: $theme-color--default;
}

.active-link {
  color: #3a4eca !important;
}
</style>