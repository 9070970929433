<template>
    <div class="section-space--pt_120">
        <div class="feature-slider__single-slide">
            <div class="feature-slider__single-slide-wrapper">
                <div class="row align-items-center">
                    <div class="col-md-6 wow move-up">
                        <div class="image">
                            <img src="../assets/img/features/concept-no-bg.png" class="img-fluid" alt="image">
                        </div>
                    </div>
                    <div class="col-md-6 wow move-up">
                        <div class="content">
                            <h4 class="title">Build New Ideas</h4>
                            <div class="text">Semantic search refers to the practice of conducting a search on the meaning behind a word or phrase. RadixSuggest allows you to find related items that are not similar in nature but cover common concepts, so you can expand on your search terms.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="feature-slider__single-slide section-space--pt_60">
            <div class="feature-slider__single-slide-wrapper">
                <div class="row align-items-center">
                    <div class="col-md-6 wow move-up">
                        <div class="content">
                            <h4 class="title">Results at a Glance</h4>
                            <div class="text">RadixAnalyse visualises the distribution of your results spatially using a heatmap. You can use this tool to identify clusters, or outliers in your data and drill down into individual items.</div>
                        </div>
                    </div>
                    <div class="col-md-6 wow move-up">
                        <div class="image">
                            <img src="../assets/img/features/map-blue.png" class="img-fluid" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="feature-slider__single-slide section-space--pt_60">
            <div class="feature-slider__single-slide-wrapper">
                <div class="row align-items-center">
                    <div class="col-md-6 wow move-up">
                        <div class="image">
                            <img src="../assets/img/features/documents-search.png" class="img-fluid" alt="image">
                        </div>
                    </div>
                    <div class="col-md-6 wow move-up">
                        <div class="content">
                            <h4 class="title">Efficiency at Your Fingertips</h4>
                            <div class="text">Designed to make finding and accessing information easier, RadiXplore will pinpoint the document page and save you time, energy and the frustration of looking through an entire collection of data.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FeatureThree'
    }
</script>