<template>
  <div class="main-container">
    <Navbar class="is-sticky"/>
    <div class="box-image-area section-space--pt_60 theme_bg-2 radix-nav">
      <SectionTitle sectionTitle="Vision, Mission and Values"/>

      <div class="container">
        <h4 class="section-title mb-15 text-center">Our Vision</h4>
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 wow move-up">
            <div class="service-grid">
              <div class="ht-service-box--two text-center style-03">
                <router-link to="">
                  <div class="content">
                    <h6 class="heading">Let no data go to waste.</h6>
                    <div class="service_text">Data is a valuable asset. But today, a lot of data is left unused because it's either too difficult to get to or people don't know what to do with it. Our aim is to change this fact by envisioning a world where not a byte of data is left unused.</div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <h4 class="section-title mb-15 text-center">Our Mission</h4>
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 wow move-up">
            <div class="service-grid">
              <div class="ht-service-box--two text-center style-03">
                <router-link to="">
                  <div class="content">
                    <h6 class="heading">To help customers streamline the process of searching, analysing, and extracting information.</h6>
                    <div class="service_text">Our mission is to help customers leverage the power of their data to make informed decisions.</div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <h4 class="section-title mb-15 text-center">Our Core Values</h4>
        <div class="row">
            <div v-for="item in items" :key="item.id" class="col-xl-6 col-lg-6 col-md-6 wow move-up">
                <div class="service-grid">
                    <div class="ht-service-box--two text-center style-03">
                        <router-link to="">
                            <div class="image mb-20">
                                <img :src="item.icon" class="img-fluid" alt="aeroland-startup-box-icon-01">
                            </div>
                            <div class="content">
                                <h6 class="heading">{{ item.heading }}</h6>
                                <div class="service_text">{{ item.text }}</div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
    <FooterMain class="radix-nav" FooterStyle="bg_drak-footer"/>
  </div>
</template>

<script>
import SectionTitle from '@/components/SectionTitle.vue';
import Navbar from '../components/Navbar';
import FooterMain from '../components/FooterMain';

export default {
  name: 'MissionValues',
  components: {
    SectionTitle,
    Navbar,
    FooterMain
  },
  data (){
    return {
        items: [
            {
                icon: require('../assets/img/icons/innovation.png'), 
                heading: 'Innovation', 
                text: 'We dare to be different by constantly looking for new ways of doing things.'
            },
            {
                icon: require('../assets/img/icons/balance.png'), 
                heading: 'Integrity', 
                text: 'We believe in being open and honest with our customers and our teams.'
            },
            {
                icon: require('../assets/img/icons/hand-shake.png'), 
                heading: 'Humility', 
                text: 'We never stop learning from others and improving ourselves.'
            },
            {
                icon: require('../assets/img/icons/unity.png'), 
                heading: 'Teamwork', 
                text: 'We strive together through mutual empowerment.'
            },
            // {
            //     icon: require('../assets/img/icons/aeroland-startup-box-icon-05.png'), 
            //     heading: 'Email Optimization', 
            //     text: 'Consider this app a platform for connecting people from everywhere based on their preferences.'
            // },
            // {
            //     icon: require('../assets/img/icons/aeroland-startup-box-icon-06.png'), 
            //     heading: 'Web Design', 
            //     text: 'Consider this app a platform for connecting people from everywhere based on their preferences.'
            // },
        ]
    }
}
}
</script>