<template>
  <div class="main-container">
    <Navbar/>
    <div id="home">
      <HeroSass/>
    </div>
    
    <div class="box-image-area section-space--pt_120 section-space--pb_20 theme_bg-2" id="about">
      <div class="container" >
        <FeatureApp/>
        <div class="popup-video-wrapper section-space--pb_60 section-space--pt_90">
            <div class="container">
              <h5 class="section-title mb-15 text-center">An Introduction</h5>
                <!-- popup video one component -->
                <PopUpVideoOne />
            </div>
        </div>
      </div>
    </div>
    <div class="container section-space--ptb_60" id="founders">
        <TeamOne/>
      </div>
    <div class="container section-space--ptb_120" id="products">
      <h3 class="section-title mb-15 text-center">Our Products</h3>
      <StartUpFeatureTwo/>
    </div>
    <!-- <div class="container section-space--ptb_120" id="pricing">
      <h3 class="section-title mb-15 text-center">Our Pricing</h3>
      <PricingSection/>
    </div> -->
    <!-- <CurveShape>
      <template v-slot:bgcolor>
        <path style="fill: #f1f2f6;
          stroke-miterlimit:10;" 
          d="M3360.5,97.739c-242,0-480-48.375-480-48.375
            S2647.5,0.5,2400.5,0.5s-480,48.375-480,48.375s-238,48.864-480,48.864s-480-48.375-480-48.375S727.5,0.5,480.5,0.5
            S0.5,48.875,0.5,48.875V108h1920h1920V48.875C3840.5,48.875,3602.5,97.739,3360.5,97.739z"/>
      </template>
    </CurveShape> -->

    <div class="box-image-area section-space--ptb_120 theme_bg-2"  id="features">
      <div class="container">
        <h3 class="section-title mb-15 text-center">Why RadiXplore?</h3>
        <div class="container">
          <ShowcaseAdvancedFeatures/>
        </div>
        <div class="container">
          <FeatureThree/>
        </div>
        
      </div>
    </div>
    
    <!-- <div id="features">
      <TabOne BgGradient="theme_bg-2">
        <template v-slot:first-tab >
          <TabThree 
            id="miming"
            BgGradient="theme_bg-2"
            title="Mining" 
            :items="tabOneItems"
            :images="tabOneImages"
          />
        </template>
        <template v-slot:second-tab>
          <TabThree 
            id="oil-gas"
            BgGradient="theme_bg-2"
            title="Oil and Gas" 
            :items="tabTwoItems"
            :images="tabTwoImages"
          />
        </template>
      </TabOne>
    </div> -->
    

    <!-- <div class="section-space--pb_120" id="testimonials">
      <div class="container">
        <div class="section-title text-center section-space--mb_60">
          <h2 class="section-title font-weight--light wow move-up">Don’t take our word for it</h2>
        </div>
        <TestimonialTwo/>
      </div>
    </div> -->
    
    
    
    <FooterMain FooterStyle="bg_drak-footer"/>
  </div>
</template>

<script>
import Navbar from '../components/Navbar';
import HeroSass from '../components/HeroSass';
// import AboutExpand from '../components/AboutExpand';
// import CurveShape from '../components/CurveShape';
// import TabOne from '../components/TabOne';
// import TabThree from '../components/TabThree';
import TeamOne from "../components/TeamOne";
import FooterMain from "../components/FooterMain";
// import FunFactOne from '../components/FunFactOne'
import StartUpFeatureTwo from '../components/StartUpFeatureTwo.vue';
import FeatureThree from '../components/FeatureThree.vue';
import ShowcaseAdvancedFeatures from '../components/ShowcaseAdvancedFeatures.vue';
import PopUpVideoOne from '@/components/PopUpVideoOne';
import FeatureApp from '@/components/FeatureApp.vue'
// import PricingSection from '../components/PricingSection.vue';

export default {
  name: 'Home',
  components: {
    Navbar,
    HeroSass,
    // AboutExpand,
    // CurveShape,
    // TabOne,
    // TabThree,
    TeamOne,
    // PricingSection,
    FooterMain,
    // FunFactOne,
    StartUpFeatureTwo,
    ShowcaseAdvancedFeatures,
    PopUpVideoOne,
    FeatureThree,
    FeatureApp
  },
  data(){
    return {
      tabOneItems: [
        {
          icon: 'fa-search-dollar',
          title: 'Assay Finder',
          subtitle: 'Find assays embedded within vintage reports.'
        },
        {
          icon: 'fa-chart-bar',
          title: 'RadixAnalyse',
          subtitle: 'Visualise distribution of results spatially.'
        },
        {
          icon: 'fa-book',
          title: 'RadixSuggest',
          subtitle: 'Discover connections between seemingly unrelated words.'
        },
      ],
      tabTwoItems: [
        {
          icon: 'fa-chart-bar',
          title: 'RadixAnalyse',
          subtitle: 'Descriptive spatial analytics to help you improve your search.'
        },
        {
          icon: 'fa-glasses',
          title: 'Nerd Search',
          subtitle: 'Use advanced syntax to customize your query so that you can uncover even the smallest details within the dataset.'
        },
        {
          icon: 'fa-file-pdf',
          title: 'PDF Viewer',
          subtitle: 'Open the PDF file to the page of your search result instantly.'
        }
      ],
      tabOneImages: [
        "AssayFinder.webp",
        "RadixAnalyse-Copper.webp",
        "RadixSuggest.gif"
      ],
      tabTwoImages: [
        "RadixAnalyse.webp",
        "RadixAnalyse.webp",
        "RadixAnalyse.webp"
      ],
      swiperOption: {
        speed: 1500,
        loop: true,
        effect: 'fade',
        autoplay: false,
        spaceBetween : 30,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
    }
    };
  }
}
</script>