import NotFound from '../pages/elements/404';
import Home from '../pages/Home';
// import UseCases from '../pages/UseCases';
import MissionValues from '../pages/MissionValues';
import SignUp from '../components/SignUp';
import SeparatePricing from '../pages/SeparatePricing';
import Blog from '../pages/Blog'
import BlogPost from '../pages/BlogPost'

export const routes = [
    {
        path:'/',
        name: 'Home',
        component: Home
    },
    {
        path: '/signup',
        name: 'Sign Up',
        component: SignUp
    },
    {
        path:'/blog',
        name: 'Blog',
        component: Blog
    },
    {
        path: '/blog/:slug',
        name: 'BlogPost',
        component: BlogPost
    },
    {
        path: '/values',
        component: MissionValues
    },
    {
        path: '/our-pricing',
        component: SeparatePricing
    },
    // {
    //     path:'/usecases',
    //     name: 'UseCases',
    //     component: UseCases
    // },
    {
        path:'*',
        name: 'NotFound',
        component: NotFound
    },
];